.react-tel-input {
    margin-top: 16px;
    margin-bottom: 8px;
    background: #212121 !important;
    position: relative;
    min-width: 0;
    padding: 0;
    border: 0;
    vertical-align: top;
    display: inline-flex;
    flex-direction: column;
    width: 100%;
  }
  
  .react-tel-input .special-label {
    background: #212121 !important;
    color: rgba(255, 255, 255, 0.7);
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    left: 0;
    top: 0;
    display: block;
    transform-origin: top left;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 24px);
    transform: translate(10px, -9px) scale(0.75);
    pointer-events: auto;
    padding-left: 5px;
    padding-right: 5px;
  }

  .react-tel-input .special-label {
    color: rgba(255, 255, 255, 0.7);
  }

  .react-tel-input:focus-within div.special-label {
    color: #00E1ED;
   }

  .react-tel-input .form-control {
    background: #212121 !important;
    border-color: rgba(255, 255, 255, 0.23);
    padding: 18.5px 14px 18.5px 58px;
    color: #fff;
  }

  .react-tel-input .form-control:hover {
    border-color: #fff;
  }

  .react-tel-input .form-control:focus {
    border-color: #00E1ED;
    box-shadow: none !important;
  }

  .react-tel-input:focus-within input {
    border-color: #00E1ED;
    box-shadow: none !important;
  }

  .react-tel-input .selected-flag {
    padding: 0 0 0 14px;
  }

  .react-tel-input .country-list {
    background-color: #212121;
    margin: 2px 0 10px -1px;
    border-radius: 0;
  }

  .react-tel-input .country-list .country.highlight {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .react-tel-input .country-list .country:hover {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .react-tel-input .country-list .country .dial-code {
    color: rgba(255, 255, 255, 0.7);
  }

  .react-tel-input .selected-flag:focus .arrow {
    border-top: 5px solid #555;
  }